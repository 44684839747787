//Global setting and initializer

$.fn.dataTable = DataTable;
$.fn.dataTableSettings = DataTable.settings;
$.fn.dataTableExt = DataTable.ext;
DataTable.$ = $;

$.extend( $.fn.dataTable.defaults, {
  responsive: true,
  pagingType: 'full'
});

$(document).on('preInit.dt', function(e, settings) {
  var api, table_id, url;
  api = new $.fn.dataTable.Api(settings);
  table_id = "#" + api.table().node().id;
  url = $(table_id).data('source');
  if (url) {
    return api.ajax.url(url);
  }
});

// turbolinks cache fix
$(document).on('turbolinks:before-cache', function() {
  // var dataTable = $($.fn.dataTable.tables(true)).DataTable();
  // if (dataTable !== null) {
  //   dataTable.clear();
  //   dataTable.destroy();
  //   return dataTable = null;
  // }
});
