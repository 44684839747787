// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("packs/toasts")
require("bootstrap")
require("datatables.net-dt")
require("packs/datatables")
require("@fortawesome/fontawesome-free")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import $ from 'jquery'
import 'jquery-mask-plugin'
import 'inputmask'

const phoneMasks = { 
  "AC": "+247-####", 
  "AD": "+376-###-###", 
  "AE": "+971-5#-###-####", 
  "AE": "+971-#-###-####", 
  "AF": "+93-##-###-####", 
  "AG": "+1 (268) ###-####", 
  "AI": "+1 (264) ###-####", 
  "AL": "+355 (###) ###-###", 
  "AM": "+374-##-###-###", 
  "AN": "+599-###-####", 
  "AN": "+599-###-####", 
  "AN": "+599-9###-####", 
  "AO": "+244 (###) ###-###", 
  "AQ": "+672-1##-###", 
  "AR": "+54 (###) ###-####", 
  "AS": "+1 (684) ###-####", 
  "AT": "+43 (###) ###-####", 
  "AU": "+61-#-####-####", 
  "AW": "+297-###-####", 
  "AZ": "+994-##-###-##-##", 
  "BA": "+387-##-#####", 
  "BA": "+387-##-####", 
  "BB": "+1 (246) ###-####", 
  "BD": "+880-##-###-###", 
  "BE": "+32 (###) ###-###", 
  "BF": "+226-##-##-####", 
  "BG": "+359 (###) ###-###", 
  "BH": "+973-####-####", 
  "BI": "+257-##-##-####", 
  "BJ": "+229-##-##-####", 
  "BM": "+1 (441) ###-####", 
  "BN": "+673-###-####", 
  "BO": "+591-#-###-####", 
  "BR": "+55 (##) ####-####", 
  "BR": "+55 (##) 7###-####", 
  "BR": "+55 (##) 9####-####", 
  "BS": "+1 (242) ###-####", 
  "BT": "+975-17-###-###", 
  "BT": "+975-#-###-###", 
  "BW": "+267-##-###-###", 
  "BY": "+375 (##) ###-##-##", 
  "BZ": "+501-###-####", 
  "CA": "+1 (###) ###-####", 
  "CD": "+243 (###) ###-###", 
  "CF": "+236-##-##-####", 
  "CG": "+242-##-###-####", 
  "CH": "+41-##-###-####", 
  "CI": "+225-##-###-###", 
  "CK": "+682-##-###", 
  "CL": "+56-#-####-####", 
  "CM": "+237-####-####", 
  "CN": "+86 (###) ####-####", 
  "CN": "+86 (###) ####-###", 
  "CN": "+86-##-#####-#####", 
  "CO": "+57 (###) ###-####", 
  "CR": "+506-####-####", 
  "CU": "+53-#-###-####", 
  "CV": "+238 (###) ##-##", 
  "CW": "+599-###-####", 
  "CY": "+357-##-###-###", 
  "CZ": "+420 (###) ###-###", 
  "DE": "+49 (####) ###-####", 
  "DE": "+49 (###) ###-####", 
  "DE": "+49 (###) ##-####", 
  "DE": "+49 (###) ##-###", 
  "DE": "+49 (###) ##-##", 
  "DE": "+49-###-###", 
  "DJ": "+253-##-##-##-##", 
  "DK": "+45-##-##-##-##", 
  "DM": "+1 (767) ###-####", 
  "DO": "+1 (809) ###-####", 
  "DO": "+1 (829) ###-####", 
  "DO": "+1 (849) ###-####", 
  "DZ": "+213-##-###-####", 
  "EC": "+593-##-###-####", 
  "EC": "+593-#-###-####", 
  "EE": "+372-####-####", 
  "EE": "+372-###-####", 
  "EG": "+20 (###) ###-####", 
  "ER": "+291-#-###-###", 
  "ES": "+34 (###) ###-###", 
  "ET": "+251-##-###-####", 
  "FI": "+358 (###) ###-##-##", 
  "FJ": "+679-##-#####", 
  "FK": "+500-#####", 
  "FM": "+691-###-####", 
  "FO": "+298-###-###", 
  "FR": "+262-#####-####", 
  "FR": "+33 (###) ###-###", 
  "FR": "+508-##-####", 
  "FR": "+590 (###) ###-###", 
  "GA": "+241-#-##-##-##", 
  "GD": "+1 (473) ###-####", 
  "GE": "+995 (###) ###-###", 
  "GF": "+594-#####-####", 
  "GH": "+233 (###) ###-###", 
  "GI": "+350-###-#####", 
  "GL": "+299-##-##-##", 
  "GM": "+220 (###) ##-##", 
  "GN": "+224-##-###-###", 
  "GQ": "+240-##-###-####", 
  "GR": "+30 (###) ###-####", 
  "GT": "+502-#-###-####", 
  "GU": "+1 (671) ###-####", 
  "GW": "+245-#-######", 
  "GY": "+592-###-####", 
  "HK": "+852-####-####", 
  "HN": "+504-####-####", 
  "HR": "+385-##-###-###", 
  "HT": "+509-##-##-####", 
  "HU": "+36 (###) ###-###", 
  "ID": "+62 (8##) ###-####", 
  "ID": "+62-##-###-##", 
  "ID": "+62-##-###-###", 
  "ID": "+62-##-###-####", 
  "ID": "+62 (8##) ###-###", 
  "ID": "+62 (8##) ###-##-###", 
  "IE": "+353 (###) ###-###", 
  "IL": "+972-5#-###-####", 
  "IL": "+972-#-###-####", 
  "IN": "+91 (####) ###-###", 
  "IO": "+246-###-####", 
  "IQ": "+964 (###) ###-####", 
  "IR": "+98 (###) ###-####", 
  "IS": "+354-###-####", 
  "IT": "+39 (###) ####-###", 
  "JM": "+1 (876) ###-####", 
  "JO": "+962-#-####-####", 
  "JP": "+81-##-####-####", 
  "JP": "+81 (###) ###-###", 
  "KE": "+254-###-######", 
  "KG": "+996 (###) ###-###", 
  "KH": "+855-##-###-###", 
  "KI": "+686-##-###", 
  "KM": "+269-##-#####", 
  "KN": "+1 (869) ###-####", 
  "KP": "+850-191-###-####", 
  "KP": "+850-##-###-###", 
  "KP": "+850-###-####-###", 
  "KP": "+850-###-###", 
  "KP": "+850-####-####", 
  "KP": "+850-####-#############", 
  "KR": "+82-##-###-####", 
  "KW": "+965-####-####", 
  "KY": "+1 (345) ###-####", 
  "KZ": "+7 (6##) ###-##-##", 
  "KZ": "+7 (7##) ###-##-##", 
  "LA": "+856 (20##) ###-###", 
  "LA": "+856-##-###-###", 
  "LB": "+961-##-###-###", 
  "LB": "+961-#-###-###", 
  "LC": "+1 (758) ###-####", 
  "LI": "+423 (###) ###-####", 
  "LK": "+94-##-###-####", 
  "LR": "+231-##-###-###", 
  "LS": "+266-#-###-####", 
  "LT": "+370 (###) ##-###", 
  "LU": "+352 (###) ###-###", 
  "LV": "+371-##-###-###", 
  "LY": "+218-##-###-###", 
  "LY": "+218-21-###-####", 
  "MA": "+212-##-####-###", 
  "MC": "+377 (###) ###-###", 
  "MC": "+377-##-###-###", 
  "MD": "+373-####-####", 
  "ME": "+382-##-###-###", 
  "MG": "+261-##-##-#####", 
  "MH": "+692-###-####", 
  "MK": "+389-##-###-###", 
  "ML": "+223-##-##-####", 
  "MM": "+95-##-###-###", 
  "MM": "+95-#-###-###", 
  "MM": "+95-###-###", 
  "MN": "+976-##-##-####", 
  "MO": "+853-####-####", 
  "MP": "+1 (670) ###-####", 
  "MQ": "+596 (###) ##-##-##", 
  "MR": "+222-##-##-####", 
  "MS": "+1 (664) ###-####", 
  "MT": "+356-####-####", 
  "MU": "+230-###-####", 
  "MV": "+960-###-####", 
  "MW": "+265-1-###-###", 
  "MW": "+265-#-####-####", 
  "MX": "+52 (###) ###-####", 
  "MX": "+52-##-##-####", 
  "MY": "+60-##-###-####", 
  "MY": "+60 (###) ###-###", 
  "MY": "+60-##-###-###", 
  "MY": "+60-#-###-###", 
  "MZ": "+258-##-###-###", 
  "NA": "+264-##-###-####", 
  "NC": "+687-##-####", 
  "NE": "+227-##-##-####", 
  "NF": "+672-3##-###", 
  "NG": "+234 (###) ###-####", 
  "NG": "+234-##-###-###", 
  "NG": "+234-##-###-##", 
  "NG": "+234 (###) ###-####", 
  "NI": "+505-####-####", 
  "NL": "+31-##-###-####", 
  "NO": "+47 (###) ##-###", 
  "NP": "+977-##-###-###", 
  "NR": "+674-###-####", 
  "NU": "+683-####", 
  "NZ": "+64 (###) ###-###", 
  "NZ": "+64-##-###-###", 
  "NZ": "+64 (###) ###-####", 
  "OM": "+968-##-###-###", 
  "PA": "+507-###-####", 
  "PE": "+51 (###) ###-###", 
  "PF": "+689-##-##-##", 
  "PG": "+675 (###) ##-###", 
  "PH": "+63 (###) ###-####", 
  "PK": "+92 (###) ###-####", 
  "PL": "+48 (###) ###-###", 
  "PS": "+970-##-###-####", 
  "PT": "+351-##-###-####", 
  "PW": "+680-###-####", 
  "PY": "+595 (###) ###-###", 
  "QA": "+974-####-####", 
  "RE": "+262-#####-####", 
  "RO": "+40-##-###-####", 
  "RS": "+381-##-###-####", 
  "RU": "+7 (###) ###-##-##", 
  "RW": "+250 (###) ###-###", 
  "SA": "+966-5-####-####", 
  "SA": "+966-#-###-####", 
  "SB": "+677-###-####", 
  "SB": "+677-#####", 
  "SC": "+248-#-###-###", 
  "SD": "+249-##-###-####", 
  "SE": "+46-##-###-####", 
  "SG": "+65-####-####", 
  "SH": "+290-####", 
  "SH": "+290-####", 
  "SI": "+386-##-###-###", 
  "SK": "+421 (###) ###-###", 
  "SL": "+232-##-######", 
  "SM": "+378-####-######", 
  "SN": "+221-##-###-####", 
  "SO": "+252-##-###-###", 
  "SO": "+252-#-###-###", 
  "SO": "+252-#-###-###", 
  "SR": "+597-###-####", 
  "SR": "+597-###-###", 
  "SS": "+211-##-###-####", 
  "ST": "+239-##-#####", 
  "SV": "+503-##-##-####", 
  "SX": "+1 (721) ###-####", 
  "SY": "+963-##-####-###", 
  "SZ": "+268-##-##-####", 
  "TC": "+1 (649) ###-####", 
  "TD": "+235-##-##-##-##", 
  "TG": "+228-##-###-###", 
  "TH": "+66-##-###-####", 
  "TH": "+66-##-###-###", 
  "TJ": "+992-##-###-####", 
  "TK": "+690-####", 
  "TL": "+670-###-####", 
  "TL": "+670-77#-#####", 
  "TL": "+670-78#-#####", 
  "TM": "+993-#-###-####", 
  "TN": "+216-##-###-###", 
  "TO": "+676-#####", 
  "TR": "+90 (###) ###-####", 
  "TT": "+1 (868) ###-####", 
  "TV": "+688-90####", 
  "TV": "+688-2####", 
  "TW": "+886-#-####-####", 
  "TW": "+886-####-####", 
  "TZ": "+255-##-###-####", 
  "UA": "+380 (##) ###-##-##", 
  "UG": "+256 (###) ###-###", 
  "UK": "+44-##-####-####", 
  "US": "+1 (###) ###-####",
  "UY": "+598-#-###-##-##", 
  "UZ": "+998-##-###-####", 
  "VA": "+39-6-698-#####", 
  "VC": "+1 (784) ###-####", 
  "VE": "+58 (###) ###-####", 
  "VG": "+1 (284) ###-####", 
  "VI": "+1 (340) ###-####", 
  "VN": "+84-##-####-###", 
  "VN": "+84 (###) ####-###", 
  "VU": "+678-##-#####", 
  "VU": "+678-#####", 
  "WF": "+681-##-####", 
  "WS": "+685-##-####", 
  "YE": "+967-###-###-###", 
  "YE": "+967-#-###-###", 
  "YE": "+967-##-###-###", 
  "ZA": "+27-##-###-####", 
  "ZM": "+260-##-###-####", 
  "ZW": "+263-#-######" 
} 

var sigla = 'BR';

function readURL(input) {
  if (input.files && input.files[0]) {
    var reader = new FileReader();
    reader.onload = function (e) {
      $('.default-exercice-image').css("display", "none")
      $('#pictureForm').css("display", "block")
      $('#pictureForm').attr('src', e.target.result);
    };
    reader.readAsDataURL(input.files[0]);
  }
}

const maskBehavior = function (val) {
  if (val.replace(/\D/g, '').length === 12) return '+00 (000) 00000-0000';
  else if (val.replace(/\D/g, '').length === 13) return '+00 (00) 00000-0000';
  else if (val.replace(/\D/g, '').length === 14) return '+0 (00) 00000-0000';
  else if (val.replace(/\D/g, '').length === 15) return '+0 (00) 00000-0000';
};

var options = { onKeyPress: 
  function(val, e, field, options) {
    field.mask(maskBehavior.apply({}, arguments), options);
  }
};


function setPhoneMask(input){
  if (input.getSelectedCountryData().iso2) {
    const sigla = input.getSelectedCountryData().iso2.toUpperCase();
    $('.telefone-paises').mask(phoneMasks[sigla]);
  }
}


document.addEventListener("turbolinks:load", () => {
  $("#goBack").on("click", function() {
    window.history.back(-1);
  });

  if (!$.fn.dataTable.isDataTable("table[id^=dttb-]")) {
    $("table[id^=dttb-]").DataTable({
      responsive: true
    });
  }

  $(".dinamicImage").on("change", function() { 
    readURL(this);
    $(".defaultImageLabel").hide();
    $(".newImageLabel").show();
  });

  if ($('#pictureForm').attr('src') == ''){
    $('#pictureForm').css("display", "none");
  }

  $('.telefone').mask(maskBehavior, options);
  $('.rg').mask('99.999.999-9');
  $('.cpf').mask('999.999.999-99');
  $('.card_number').mask('9999999999999999'); 
  $('.cnpj').mask('00.000.000/0000-00');
  $('.card_expiration').mask('99/99');
  $('.cep').mask('99999-999');

  $('.dismissNovoTreino').on("click", function() {
    $('.modalNovoTreino').hide();
    $('.modal-backdrop').hide();
  });

  $('.telefone-paises').on('keyup', function() {
    if ($(this).val().length > phoneMasks[sigla].length) {
      $('.telefone-paises').val($('.telefone-paises').val().slice(0, phoneMasks[sigla].length - $(this).val().length));
    } 
  });

  const telefone_paises = document.getElementsByClassName('telefone-paises')[0];
  if (telefone_paises) {
    var input;

    if (telefone_paises.value == '') {
      input = intlTelInput(telefone_paises, { initialCountry: 'br' });
    } else {
      input = intlTelInput(telefone_paises);
    }
  
    setPhoneMask(input);
    telefone_paises.addEventListener("countrychange", function(e) {
      setPhoneMask(input);
    }); 
  }  

  if ($('#exercice-method').val() == 'post') {
    $('.first-step').show();
    $('.second-step').hide();
    $('.exercicio-treino-register-header .actions').hide();
  } else {
    $('.first-step').hide();
    $('.second-step').show();
    $('.exercice-next-step').hide();
    $('.exercicio-treino-register-next-step-btn').hide();
  }

  $('#exercicio_treino_exercicio_id').on('change', function() {
    const exercicio_id = this.value;
    
    if (exercicio_id == '') {
      $('.no-exercice-selected').css("display","block");
      $('.exercice-modal-img').css("display","none");
    } else {
      $('.exercice-modal-img').css("display","flex");
      $('.no-exercice-selected').css("display","none");

      $.ajax({
        url: `/admin/exercicios/${exercicio_id}.js`,
      });
    }
  });

  $('.exercice-next-step').on("click", function(){
    if (!$('.exercice-next-step').hasClass("disabled")) {
      $(this).hide();
      $('.first-step').hide();
      $('.second-step').show();
      $('.exercicio-treino-register-header .actions').show();
    }
  });
  
  
  $('.default-accordion .accordion-btn').on('click', function(event) {
    if ((event.target.tagName !== 'svg' && event.target.className && event.target.className.includes('exercice-dropdown-btn')) || event.target.tagName == 'INPUT') {
      return 
    }

    if ($(this).parent().attr('class').includes('only-click-arrow-accordion')) {
      return
    }

    if ($(this).parent().hasClass('opened')) {
      $(this).next().height(0)
    } else {
      $(this).next().height($(this).next().prop("scrollHeight"))
    }
    $(this).parent().toggleClass('opened')

  })

  $('.only-click-arrow-accordion .arrow-btn').on('click', function() {
    if ($(this).parent().parent().parent().parent().hasClass('opened')) {
      $(this).parent().parent().parent().next().height(0)
    } else {
      $(this).parent().parent().parent().next().height($(this).parent().parent().parent().next().prop("scrollHeight"))
    }
    $(this).parent().parent().parent().parent().toggleClass('opened')

  })
});